* {box-sizing: border-box;}
html {
  width: 100%;
  height:100%;
}
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(55, 55, 55);
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  text-rendering: optimizeLegibility;
}