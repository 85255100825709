.template-css {
  display: grid;
  grid-template-areas: "header"
                      "prime"
                      "footer";
  grid-template-rows: 3.3rem auto fit-content(4rem);
  width: 100vw;
  max-width: 680px;
  height: 100vh;
  margin: 0 auto;
  background-color: rgb(0, 50, 0);
}
/*-----------------------------*/
.main-elem {
  grid-area: prime;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: rgb(35, 35, 35);
  font-size: 0.8rem;
}
/*-----------------------------*/
.spacer-12 {
  width: 100%;
  padding: 6px 0;
}
.spacer-24 {
  width: 100%;
  padding: 12px 0;
}
.email-css {
  font-size: 1.2rem;
  text-decoration: none;
  color: rgb(240, 240, 100);
}
.email-css:hover {color: rgb(170, 170, 170);}
.warning-css {
  color: rgb(255, 0, 0);
  font-weight: bold;
}
.recaptcha-css {
  width: 300px;
  margin: 5px auto;
}
.cover-image {
  width: 50%;
  max-width: 480px;
}
/* ================================ */
@media (min-width: 28rem) {
  .main-elem {font-size: 0.9rem;}
  .cover-image {width: 60%;}
}
@media (min-width: 32rem) {
  .main-elem {font-size: 1rem;}
  .cover-image {width: 70%;}
}