.page-button {
  width: 100%;
  margin: 3px 0;
  padding: 9px 0;
  background-color: rgb(65, 65, 65);
}
.page-button:active {
  background-color: rgb(0, 65, 0);
}
.scroll-button {
  display: none;
  width: 150px;
  padding: 4px;
  background-color: rgb(90, 0, 0);
  border-radius: 12px;
}
.display-button {display: block;}
/*-----------------------------*/
@media (hover: hover) and (pointer: fine) {
  .page-button:hover {
    background-color: rgb(95, 95, 95);
  }
}
/*-----------------------------*/