.header-fixed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 60;
  width: 100%;
  max-width: 680px;
  height: 3.3rem;
  margin: 0 auto;
  padding: 0 12px;
  background-color: rgb(15, 15, 0);
  font-size: 0.8rem;
}
#home-comp .header-fixed {justify-content: center;}
#home-comp .header-glada {display: none;}
#page-comp .header-fixed:hover {
  background-color: rgb(65, 65, 65);
}
.header-elem  .header-fixed {
  grid-area: header;
  display: flex;
  background-color: rgb(90, 90, 90);
}
.gtr-lessons-text {
  font-size: 1.4rem;
  font-weight: bold;}
/* -------------------------------- */
#home-comp .glata-text {
  animation-duration: 2s;
  animation-name: color-change;
}
@keyframes color-change {
  from {
    color: rgb(255, 255, 0);
    text-shadow: 0 0 12px rgb(255, 255, 255);
  }
  to {
    color: rgb(255, 255, 255);
    text-shadow: 0 0 0 rgb(0, 0, 0);
  }
}
/* -------------------------------- */
#page-comp .header-fixed .home-text {
  animation-name: infinite-jump;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes infinite-jump {
  from {
    margin-bottom: 3px;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 8px rgba(0, 255, 0, 0);
  }
  50% {
    margin-bottom: 3px;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 8px rgba(0, 255, 0, 0);
  }
  to {
    margin-bottom: -5px;
    color: rgb(0, 100, 0);
    text-shadow: 0 0 8px rgba(160, 255, 160, 1);
  }
}
/* ================================ */
@media (min-width: 24rem) {
  .header-fixed {font-size: 1rem;}
}
@media (min-width: 32rem) {
  #home-comp .header-fixed {justify-content: space-between;}
  #home-comp .header-glada {display: block;}
}